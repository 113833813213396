<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="patientData"
    :single-expand="true"
    :expanded.sync="expanded"
    show-expand
    item-key="patient_code"
  >
    <template v-slot:expanded-item="{ patients, item }">
      <td :colspan="100">
        <div class="d-flex justify-space-around">
          <div>
            Latest questionnaire submitted: <br />
            <b>{{ item.latest_questionnaire_submitted }}</b>
          </div>
          <div>
            Latest wearable data collected: <br />
            <b>{{ item.latest_wearable_data_collected }}</b>
          </div>
          <div>
            Latest environmental data collected
            <br />
            <b>{{ item.latest_environmental_data_collected }}</b>
          </div>
          <div>
            Latest AI model executed:
            <br /><b>{{ item.latest_ai_model_executed }}</b>
          </div>
        </div>
      </td>
    </template>
    <template v-slot:item.notifications="{ item }">
      <v-row>
        <v-col>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <i
                v-on="on"
                :class="
                  getClassObject(item.notifications.includes('questionnaire'))
                "
                >receipt_long</i
              >
            </template>
            <span>Questionnaire notification</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-img
                width="32px"
                v-on="on"
                :src="
                  item.notifications.includes('position')
                    ? require('@/assets/env_sens_small.png')
                    : require('@/assets/env_sens_small_gray.png')
                "
              >
              </v-img>
            </template>
            <span>Location notification</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <i
                v-on="on"
                :class="getClassObject(item.notifications.includes('wearable'))"
                >watch</i
              >
            </template>
            <span>Wearable notification</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <i
                style="font-size: 28px"
                v-on="on"
                :class="
                  getClassObject(item.notifications.includes('ai_models'))
                "
                >psychology</i
              >
            </template>
            <span>Ai models notification</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer.prepend>
      <div style="width: 300px; position: relative">
        <div style="position: absolute; top: -20px">
          <v-select
            full-width
            label="Select file format to export"
            :items="fileExportFormatItems"
            v-model="selectedFileExportFormat"
            outlined
            dense
            color="#f60076"
            style="width: 90%;"
          >
          </v-select>
          <v-btn
            color="#f60076"
            class="white--text mx-2"
            style="position: absolute; right: -80px; top: 0px; margin-top: 0;"
            @click="exportToCsv"
          >
            Export
          </v-btn>
        </div>
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <template>
        <v-menu left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(action, index) in actionsList"
              :key="index"
              @click="handleAction(item.patient_code, action.title)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ action.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </template>
  </v-data-table>
</template>

<script>
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Services from "../rest/Services";

export default {
  mounted: function () {},
  props: {
    patientData: Array,
  },
  data() {
    return {
      selected: [],
      expanded: [],
      headers: [
        {
          text: "Patient code",
          value: "patient_code",
        },
        { text: "Disease type", value: "disease_type" },
        {
          text: "Hospital center",
          value: "hospital_center",
        },
        { text: "Latest activity", value: "latest_activity" },
        { text: "Activity type", value: "activity_type" },
        { text: "Notifications", value: "notifications" },
        { text: "Actions", value: "actions" },
      ],
      fileExportFormatItems: ["CSV"],
      selectedFileExportFormat: null,
      actionsList: [{ title: "Detailed view" }, { title: "Edit" }],
    };
  },
  methods: {
    exportToCsv(){
      console.log(this.patientData);

      let params = {
        data: this.patientData
      }

      Services.exportIndividualDataToCsv(params).then((response) => {
        console.log(response);

        const csvBlob = new Blob([response.data], { type: 'text/csv' });
        // Create a link element, set href to the Blob URL, and trigger download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(csvBlob);
        link.download = 'individual_monitoring.csv'; // The filename for the downloaded csv
        document.body.appendChild(link);
        link.click();
        // Cleanup: Remove the link after download
        document.body.removeChild(link);
      }
      ).catch((error) => {
        
        console.log("Error generating csv:", error);
      });
    },
    getClassObject(isActive) {
      return {
        "material-icons": true,
        active: isActive,
        inactive: !isActive,
      };
    },
    handleAction(id, action) {
      if (action === "Detailed view") {
        this.$emit("openDetailedInfo", id);
      } else if (action === "Edit")
        console.log("action and id :>> ", action + " " + id);
    },
  },
};
</script>

<style>
.active {
  color: #f60076;
}
.inactive {
  color: lightgray;
}
</style>
