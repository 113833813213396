import api from "./api";
class Services {
    login(params) {
        return api.post("/account/login", params);

    }
    getPatientData() {
        return api.get("/patient/adminDashboard/individual/data");
    }
    getPatientFilteredData(params) {
        return api.post("/patient/adminDashboard/individual/filter-data", params);
    }
    getDeviceFilteredData(params) {
        return api.post("/patient/adminDashboard/devices/filter-devices", params);
    }
    getFilterMetadata() {
        return api.get("/patient/adminDashboard/v2/filter-metadata")
    }
    getDetailedData(params) {
        return api.get(`/patient/adminDashboard/detailed?accessCode=${params}`)
    }
    getDeviceData() {
        return api.get("/patient/adminDashboard/devices")
    }
    getCohortData() {
        return api.get("/patient/adminDashboard/cohorts")
    }
    getCohortMetadata() {
        return api.get("/patient/adminDashboard/cohorts/metadata")
    }
    saveCohort(params) {
        return api.post("/patient/adminDashboard/cohorts/add", params)
    }
    loadCohort(params) {
        return api.get(`/patient/adminDashboard/cohorts/load?cohortId=${params}`)
    }
    loadCohortData(params) {
        return api.post("/patient/adminDashboard/cohorts/load-data", params)
    }
    atmotubeBackfill(params) {
        return api.post("/device/data/atmotube/manual-backfill", params)
    }
    garminBackfill(params) {
        return api.post("/device/data/garmin/manual-backfill", params)
    }
    addNewDevice(params) {
        return api.post("device/management/pilot/register", params)
    }
    editDevice(params) {
        return api.post("device/management/pilot/edit", params)
    }
    removeDevice(params) {
        return api.post("device/management/pilot/remove", params)
    }
    exportIndividualDataToCsv (params) {
        return api.post("/patient/adminDashboard/individual/data/csv", params);
    }
    exportDevicesToCsv (params) {
        return api.post("/patient/adminDashboard/devices/csv", params);
    }
    getQuestionnaires() {
        return api.get("/patient/adminDashboard/questionnaires")
    }
    getQuestionnairesFilteredData(params) {
        return api.post("/patient/adminDashboard/questionnaires/filter-questionnaires", params);
    }

}
export default new Services();
