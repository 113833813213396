<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="deviceData"
    show-select
    item-key="internal_device_code"
    class="elevation-1"
    :single-select="true"
    @input="onSelectionChange"
  >
    <template v-slot:footer.prepend>
      <div style="width: 300px; position: relative">
        <div style="position: absolute; top: -20px">
          <v-select
            full-width
            label="Select file format to export"
            :items="fileExportFormatItems"
            v-model="selectedFileExportFormat"
            outlined
            dense
            color="#f60076"
            style="width: 90%;"
          >
          </v-select>
          <v-btn
            color="#f60076"
            class="white--text mx-2"
            style="position: absolute; right: -80px; top: 0px; margin-top: 0;"
            @click="exportToCsv"
          >
            Export
          </v-btn>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import Services from '../rest/Services';
export default {
  mounted: function () {},
  props: {
    deviceData: Array,
  },
  data() {
    return {
      selected: [],
      expanded: [],
      headers: [
        {
          text: "Hospital center",
          value: "center_name",
        },
        {
          text: "Category",
          value: "category",
        },
        { text: "Brand", value: "brand" },
        {
          text: "Model",
          value: "model",
        },
        { text: "Internal Device Code", value: "internal_device_code" },
        { text: "Serial number", value: "serial_number" },
        { text: "MAC Address", value: "mac_address" },
        { text: "Status", value: "status" },
      ],
      fileExportFormatItems: ["CSV"],
      selectedFileExportFormat: null,
    };
  },
  methods: {
    exportToCsv(){
      console.log(this.deviceData);

      let params = {
        devices: this.deviceData
      }

      Services.exportDevicesToCsv(params).then((response) => {
        console.log(response);

        const csvBlob = new Blob([response.data], { type: 'text/csv' });
        // Create a link element, set href to the Blob URL, and trigger download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(csvBlob);
        link.download = 'devices.csv'; // The filename for the downloaded csv
        document.body.appendChild(link);
        link.click();
        // Cleanup: Remove the link after download
        document.body.removeChild(link);
      }
      ).catch((error) => {
        
        console.log("Error generating csv:", error);
      });
    },
    onSelectionChange(selection) {
      console.log(selection);
      this.selected = selection; // Update selected items
      this.$emit("selectionChanged", selection.length ? selection[0] : null); // Emit only one item for single select
    }
  }
};
</script>
