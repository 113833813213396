<template>
  <div class="mx-10">
    <v-form ref="deviceForm" v-model="isValid">
      <v-row>
        <v-select
          color="#f60076"
          v-model="selectedDeviceCategory"
          :items="deviceCategoryItems"
          label="Device category"
          outlined
          :rules="[requiredRule]"
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          color="#f60076"
          v-model="selectedDeviceBrandAndModel"
          :items="deviceBrandAndModelItems"
          item-text="name"
          item-value="item"
          label="Device brand and model"
          outlined
          :rules="[requiredMultiSelectRule]"
        ></v-select>
      </v-row>
      <v-row>
        <v-select
          color="#f60076"
          label="Hospital"
          :items="hospitalItems"
          v-model="selectedHospital"
          outlined
          :rules="[requiredRule]"
        ></v-select>
      </v-row>
      <v-row>
        <v-text-field
          color="#f60076"
          placeholder="Internal device code"
          outlined
          v-model="deviceCode"
          :rules="[requiredRule]"
        >
        </v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          color="#f60076"
          placeholder="Serial number"
          outlined
          v-model="serialNumMacAdd"
          :rules="[requiredRule]"
        >
        </v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          color="#f60076"
          placeholder="MAC address"
          outlined
          v-model="macAddress"
          :rules="[requiredMacRule]"
        >
        </v-text-field>
      </v-row>
    </v-form>
    
    <!-- ✅ Snackbar Notification -->
    <v-snackbar v-model="snackbar.show" :timeout="3000" :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-btn color="pink darken-1" class="white--text" @click="addDevice">
      Add Device
    </v-btn>
  </div>
</template>

<script>
import Services from '../rest/Services';
export default {
  props: {
    deviceMetadata: Object
  },
  mounted: function () {
    console.log(this.deviceMetadata);
    this.deviceCategoryItems = this.deviceMetadata.device_filters.device_category;
    let brandsAndModels = [];
    for (let i = 0; i < this.deviceMetadata.device_filters.device_brand_and_models.length; i++) {
      let newBrandAndModel = {
        device_category: this.deviceMetadata.device_filters.device_brand_and_models[i].device_category,
        device_brand: this.deviceMetadata.device_filters.device_brand_and_models[i].device_brand,
        device_model: this.deviceMetadata.device_filters.device_brand_and_models[i].device_model,
        name: this.deviceMetadata.device_filters.device_brand_and_models[i].device_brand + " - " + this.deviceMetadata.device_filters.device_brand_and_models[i].device_model
      }
      brandsAndModels.push (newBrandAndModel);
    }
    this.deviceBrandAndModelItems = brandsAndModels;
    this.hospitalItems = this.deviceMetadata.general_info_filters.clinical_center;

  },
  data() {
    return {
      isValid: false, // Track form validation state
      deviceCategoryItems: [],
      selectedDeviceCategory: null,
      deviceBransAndModels: [],
      deviceBrandAndModelItems: [],
      selectedDeviceBrandAndModel: null,
      hospitalItems: [],
      selectedHospital: null,
      deviceCode: "",
      serialNumMacAdd: "",
      macAddress: "",
      // Validation Rules
      requiredRule: (v) => !!v || "This field is required",
      requiredMultiSelectRule: (v) =>
        (v && v.length > 0) || "Select at least one item",
      requiredMacRule: (v) => this.selectedDeviceCategory !== 'ENVIRONMENT' || 
      (!!v && this.selectedDeviceCategory === 'ENVIRONMENT') || "This field is required if category is ENVIRONMENT",
      // Snackbar Notification
      snackbar: {
        show: false,
        message: "",
        color: "success", // Default color
      },
    };
  },
  methods: {
    showNotification(message, type = "success") {
      this.snackbar.message = message;
      this.snackbar.color = type === "error" ? "red darken-2" : "green darken-2";
      this.snackbar.show = true;
    },
    addDevice() {
      // Validate form before submitting
      if (!this.$refs.deviceForm.validate()) {
        console.log("Validation failed!");
        return;
      }

      let brand, model, category;

      for (let i = 0; i < this.deviceBrandAndModelItems.length; i++)
        if (this.selectedDeviceBrandAndModel === this.deviceBrandAndModelItems[i].name) {
          brand = this.deviceBrandAndModelItems[i].device_brand;
          model = this.deviceBrandAndModelItems[i].device_model;
          category = this.deviceBrandAndModelItems[i].device_category;
        }

      let params = [
        {
          internal_device_code: this.deviceCode,
          hospital_name: this.selectedHospital,
          brand: brand,
          model: model,
          category: category,
          mac_address: this.macAddress,
          serial_number: this.serialNumMacAdd
        }
      ]

      console.log(params);

      Services.addNewDevice(params).then((response) => {
        console.log(response);
        //console.log(response.data);
        // ✅ Show success notification
        this.showNotification("Device added successfully!");

        // Reset form after successful submission
        this.$refs.deviceForm.reset();
        }
      ).catch((error) => {
        
        console.log("Error adding device:", error);
        this.showNotification("Error adding device", "error");
      });
    }
  }
};
</script>
