<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="questionnaireData"
    item-key="questionnaire_code"
    class="elevation-1"
    show-select
    :single-select="true"
    @input="onSelectionChange"
  >
    
  </v-data-table>
</template>

<script>
export default {
  mounted: function () {},
  props: {
    questionnaireData: Array,
  },
  data() {
    return {
      selected: [],
      expanded: [],
      headers: [
        {
          text: "Questionnaire code",
          value: "questionnaire_code",
        },
        { text: "Questionnaire name", value: "questionnaire_name" },
        {
          text: "Disease type",
          value: "sclerosis_type",
        },
        { text: "Number of questions", value: "num_of_questions" },
        { text: "Order in app", value: "order_in_app" }, /*
        { text: "App title label", value: "app_title_label" },
        { text: "App intro label", value: "intro_label" }, */
      ]
    };
  },
  methods: {
    onSelectionChange(selection) {
      console.log(selection);
      this.selected = selection; // Update selected items
      this.$emit("selectionChanged", selection.length ? selection[0] : null); // Emit only one item for single select
    }
  },
};
</script>
