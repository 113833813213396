<template>
    <highcharts :constructor-type="'chart'" :options="chartOptions" ref="chart"></highcharts>
  </template>
  
  <script>
  export default {
    props: {
      categoryData: Array, // ✅ Expecting data from parent
      categories: Array
    },
    data() {
      return {
        chartOptions: {
            credits: { enabled: false },
        chart: { type: "column" },
        title: { text: "Data visualization" },
        xAxis: { categories: [], title: { text: "Categories" } },
        yAxis: { min: 0, title: { text: "Values" } },
        legend: { align: "center", verticalAlign: "top", layout: "horizontal" },
        plotOptions: { column: { borderWidth: 0, pointPadding: 0.2 } },
        series: []
        },
      };
    },
    watch: {
        categoryData: {
        deep: true,
        handler(newData) {
            console.log(newData);
            this.updateChart();
        }
        },
        categories: {
        deep: true,
        handler(newCategories) {
            console.log(newCategories);
            this.updateChart();
        }
        }
    },
    methods: {
        updateChart() {
            // ✅ Ensure Highcharts instance is available before updating
            if (this.$refs.chart) {
                this.chartOptions.series = this.categoryData;
                this.chartOptions.xAxis.categories = this.categories;
            }
            console.log(this.chartOptions.series);
            console.log(this.chartOptions.xAxis.categories);
        }
    }
  };
  </script>