<template>
    <div class="mx-10">
        <v-row style="height: 100px;">
            <span>
                Are you sure you want to remove device {{ internalDeviceCode.internal_device_code }} from the system?
            </span>
        </v-row>
            <v-btn color="pink darken-1" class="white--text" @click="addDevice">
                Remove Device
            </v-btn>
      
      <!-- ✅ Snackbar Notification -->
      <v-snackbar v-model="snackbar.show" :timeout="3000" :color="snackbar.color">
        {{ snackbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  import Services from '../rest/Services';
  export default {
    props: {
        internalDeviceCode: Object
    },
    watch: {
        internalDeviceCode: {
            immediate: true, // Run on component mount
            deep: true,      // Detect nested changes
            handler(newVal, oldVal) {
                if (newVal) {
                    console.log("🚀 Props Changed:", oldVal, "➡️", newVal);
                }
            }
        }
    },
    mounted: function () {
      
    },
    data() {
      return {
        // Snackbar Notification
        snackbar: {
          show: false,
          message: "",
          color: "success", // Default color
        },
      };
    },
    methods: {
      showNotification(message, type = "success") {
        this.snackbar.message = message;
        this.snackbar.color = type === "error" ? "red darken-2" : "green darken-2";
        this.snackbar.show = true;
      },
      addDevice() {
        // Validate form before submitting
        
        let params = 
          {
            internal_device_code: this.internalDeviceCode.internal_device_code
          }
        
  
        console.log(params);
  
        Services.removeDevice(params).then((response) => {
          console.log(response);
          //console.log(response.data);
          // ✅ Show success notification
          this.showNotification("Device removed successfully!");
          }
        ).catch((error) => {
          
          console.log("Error removing device:", error);
          this.showNotification("Error removing device", "error");
        });
      }
    }
  };
  </script>