<template>
    <div>
      <v-tabs
        v-model="tabs"
        active-class="custom-hover"
        style="width: 200px; margin: 0 auto"
        fixed-tabs
        height="40"
        color="#f60076"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-tab
              top
              v-bind="attrs"
              v-on="on"
              href="#mobile-tabs-5-1"
              class="primary--text"
            >
              <v-icon color="#f60076">mdi-information</v-icon>
            </v-tab>
          </template>
          <span>Basic information</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-tab
              v-bind="attrs"
              v-on="on"
              href="#mobile-tabs-5-2"
              class="primary--text"
            >
              <v-icon color="#f60076">mdi-database</v-icon>
            </v-tab>
          </template>
          <span>Data</span>
        </v-tooltip>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item v-for="i in 2" :key="i" :value="'mobile-tabs-5-' + i">
          <v-card flat>
            <v-card-text>
              <template v-if="i === 1">                
                <v-card width="550" style="margin: 0 auto">
                  <v-card-text>
                    <v-row>
                      <v-col cols="9">
                        <b><span>Latest model run:</span></b
                        ><br />
                        <b><span>Latest runtime:</span></b
                        ><br />
                        <b><span>Number of models run:</span></b
                        ><br />
                        <b
                          ><span
                            >Number of different models run:</span
                          ></b
                        >
                      </v-col>
                      <v-col cols="3">
                        <span>{{
                          modelsData.basic_info.latest_model
                        }}</span
                        ><br />
                        <span>{{
                          modelsData.basic_info.latest_runtime
                        }}</span
                        ><br />
                        <span>{{
                          modelsData.basic_info.total_models_run
                        }}</span
                        ><br />
                        <span>{{
                          modelsData.basic_info.num_of_different_models_run
                        }}</span
                        ><br />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
              <template v-if="i === 2">
                <v-card width="400" style="margin: 0 auto" class="mb-5">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <b><span>Run last week:</span></b
                        ><br />
                        <b><span>Run last month:</span></b
                        ><br />
                        <b><span>Run last six months:</span></b>
                      </v-col>
                      <v-col class="text-center">
                        <span>{{
                          modelsData.basic_info.models_run_last_week
                        }}</span
                        ><br />
                        <span>{{
                          modelsData.basic_info.models_run_last_month
                        }}</span
                        ><br />
                        <span>{{
                          modelsData.basic_info.models_run_last_six_months
                        }}</span
                        ><br />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <Chart :histogramData="modelsData.data.histogram_data" />
                <v-card>
                  <template>
                    <v-simple-table
                      dense
                      height="225px"
                      class="mt-5"
                      fixed-header
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Model name</th>
                            <th class="text-left">
                              Number of runtimes
                            </th>
                            <th class="text-left">
                              Last model run
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in modelsData.data
                              .table_data"
                            :key="index"
                          >
                            <td>{{ item.model_name }}</td>
                            <td>{{ item.num_run }}</td>
                            <td>{{ item.latest_date_run }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-card>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </template>
  
  <script>
  import Chart from "../components/Chart.vue";
  import Utils from "../utils/utils";
  export default {
    components: {
      Chart,
    },
    props: {
      modelsData: Object,
    },
    mounted: function () {
      console.log("this.modelsData :>> ", this.modelsData);
    },
    data() {
      return {
        utils: Utils,
        tabs: null,
      };
    },
  };
  </script>
  