<template>
    <div class="panel">
        <h3>Cohort: {{ cohort.cohort_name }}</h3>
        <v-form ref="form">
            <v-row>
                <v-col cols="6">
                    <label class="treeview-label">Select Data Category</label>
                    <div class="treeview-container">
                        <v-treeview
                        :items="dataCategoryItems"
                        activatable
                        open-all
                        item-key="id"
                        dense
                        color="#f60076"
                        selection-type="leaf"
                        multiple="false"
                        class="treeview-custom"
                        >
                        <template v-slot:label="{ item }">
                            <span
                            :style="{ cursor: item.children ? 'default' : 'pointer', opacity: item.children ? 0.6 : 1 }"
                            @click="selectLeaf(item)"
                            >
                                {{ item.name }}
                            </span>
                        </template>
                        </v-treeview>
                    </div>
                </v-col>
                <v-col cols="6">
                    <label class="treeview-label">Select Group by(s)</label>
                    <v-select
                    color="#f60076"
                    v-model="selectedGroups"
                    :items="groupItems"
                    label="Group by"
                    outlined
                    dense
                    clearable
                    multiple
                    ></v-select>

                    <label v-if="showAggregation" class="treeview-label">Aggregation</label>
                    <v-select
                    v-if="showAggregation"
                    color="#f60076"
                    v-model="selectedAggregation"
                    :items="aggregations"
                    label="Aggregation"
                    outlined
                    dense
                    clearable
                    ></v-select>
                    <v-btn
                        color="#f60076"
                        class="white--text mx-2 my-2"
                        @click="loadData"
                    >
                        Load Data
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <BarChartVue :categoryData="chartData"
                    :categories="categories"/>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import Services from '../rest/Services';
import BarChartVue from './BarChart.vue';
export default {
    components: {
        BarChartVue
    },
    props: {
        cohort: Object,
    },
    watch: {
        cohort: {
            immediate: true, // Run on component mount
            deep: true,      // Detect nested changes
            handler(newVal, oldVal) {
                if (newVal) {
                    console.log("🚀 Props Changed:", oldVal, "➡️", newVal);

                    if (!newVal.filter_criteria || !newVal.filter_criteria.sclerosis_type) {
                        this.sclerosisType = 'GENERAL';
                        this.dataCategoryItems = this.generalDataCategoryItems;
                    }
                    else if (newVal.filter_criteria.sclerosis_type === 'ALS') {
                        this.sclerosisType = 'ALS';
                        this.dataCategoryItems = this.alsDataCategoryItems;
                    }
                    else if (newVal.filter_criteria.sclerosis_type === 'MS') {
                        this.sclerosisType = 'MS';
                        this.dataCategoryItems = this.msDataCategoryItems;
                    }

                    this.loadCohort();
                }
            }
        }
    },
    mounted: function () {
        console.log(this.cohort);
        if (!this.cohort.filter_criteria || !this.cohort.filter_criteria.sclerosis_type) {
            this.sclerosisType = 'GENERAL';
            this.dataCategoryItems = this.generalDataCategoryItems;
        }
        else if (this.cohort.filter_criteria.sclerosis_type === 'ALS') {
            this.sclerosisType = 'ALS';
            this.dataCategoryItems = this.alsDataCategoryItems;
        }
        else if (this.cohort.filter_criteria.sclerosis_type === 'MS') {
            this.sclerosisType = 'MS';
            this.dataCategoryItems = this.msDataCategoryItems;
        }

        this.loadCohort();
    },
    data: function () {
        return {
            chartData: [],
            categories: [],
            sclerosisType: null,
            patientIds: [],
            patientCodes: [],
            selectedDataCategory: null,
            selectedGroups: [],
            selectedAggregation: null,
            aggregations: [],
            showAggregation: false,
            groupItems:["Age group", "Gender", "Marital status", "Ethnicity", "Hospital"],
            generalDataCategoryItems: [
                {
                    id: 1,
                    name: "General",
                    children: [
                        {id: 2, name: "Count"}
                    ]
                }
            ],
            alsDataCategoryItems: [
                {
                    id: 1,
                    name: "General",
                    children: [
                        {id: 2, name: "Count"},
                        {id: 22, name: "Weight", aggregations: ["avg", "max", "min"]},
                        {id: 23, name: "BMI", aggregations: ["avg", "max", "min"]}
                    ]
                },
                {
                    id: 19,
                    name: "Disease History",
                    children: [
                        {id: 20, name: "Onset site(s)"},
                        {id: 21, name: "Family History"},
                        {id: 24, name: "Smoking"}
                    ]
                },
                {
                    id: 3,
                    name: "ALSFRS-R",
                    children: [
                        {id: 4, name: "Total Score", aggregations: ["avg", "max", "min"]},
                        {id: 5, name: "Speech", aggregations: ["avg", "max", "min"]},
                        {id: 6, name: "Salivation", aggregations: ["avg", "max", "min"]},
                        {id: 7, name: "Swallowing", aggregations: ["avg", "max", "min"]},
                        {id: 8, name: "Handwriting", aggregations: ["avg", "max", "min"]},
                        {id: 9, name: "Cutting food and handling utensils", aggregations: ["avg", "max", "min"]},
                        {id: 10, name: "Dressing and hygiene", aggregations: ["avg", "max", "min"]},
                        {id: 11, name: "Turning in bed", aggregations: ["avg", "max", "min"]},
                        {id: 12, name: "Walking", aggregations: ["avg", "max", "min"]},
                        {id: 13, name: "Climbing stairs", aggregations: ["avg", "max", "min"]},
                        {id: 14, name: "Dyspnea", aggregations: ["avg", "max", "min"]},
                        {id: 15, name: "Orthopnea", aggregations: ["avg", "max", "min"]},
                        {id: 16, name: "Respiratory insufficiency", aggregations: ["avg", "max", "min"]}
                    ]
                },
                {
                    id: 17,
                    name: "Instrumental Evaluations",
                    children: [
                        {id: 18, name: "FVC sitting percent", aggregations: ["avg", "max", "min"]}
                    ]
                },
                {
                    id: 25,
                    name: "AI Models",
                    children: [
                        {id: 26, name: "ALS-M0-Death probabilities", aggregations: ["avg", "max", "min"]},
                        {id: 27, name: "ALS-M0-PEG probabilities", aggregations: ["avg", "max", "min"]},
                        {id: 28, name: "ALS-M0-NIV probabilities", aggregations: ["avg", "max", "min"]},
                        {id: 29, name: "ALS-M6-Death probabilities", aggregations: ["avg", "max", "min"]},
                        {id: 30, name: "ALS-M6-PEG probabilities", aggregations: ["avg", "max", "min"]},
                        {id: 31, name: "ALS-M6-NIV probabilities", aggregations: ["avg", "max", "min"]},
                        {id: 32, name: "WP5 ALS predicted scores", aggregations: ["avg", "max", "min"]}
                    ]
                }
            ],
            msDataCategoryItems: [
                {
                    id: 1,
                    name: "Health Data",
                    children: [
                        { id: 2, name: "Heart Rate" },
                        { id: 3, name: "Blood Pressure" }
                    ]
                },
                {
                    id: 4,
                    name: "Wearables",
                    children: [
                        { id: 5, name: "Smartwatch Data" },
                        { id: 6, name: "Step Count" }
                    ]
                }
            ],
            dataCategoryItems: [
                {
                    id: 1,
                    name: "Health Data",
                    children: [
                        { id: 2, name: "Heart Rate" },
                        { id: 3, name: "Blood Pressure" }
                    ]
                },
                {
                    id: 4,
                    name: "Wearables",
                    children: [
                        { id: 5, name: "Smartwatch Data" },
                        { id: 6, name: "Step Count" }
                    ]
                }
            ]
        }
    },
    methods: {
        loadCohort: function () {
            
            console.log(this.cohort.id);

            Services.loadCohort(this.cohort.id).then((response) => {
                console.log(response);
                this.patientCodes = response.data.patient_codes;
                this.patientIds = response.data.patient_ids;
            });
        },
        loadData() {
            console.log("load data");

            console.log(this.selectedGroups);

            let params = {
                patient_codes: this.patientCodes,
                patient_ids: this.patientIds,
                data_type: this.sclerosisType,
                data_category: this.selectedDataCategory.name,
                group_by: this.selectedGroups,
                aggregation: this.selectedAggregation
            }

            console.log(params);

            Services.loadCohortData(params).then((response) => {
                console.log(response);
                this.categories = response.data.categories;
                this.chartData = response.data.series;
            });
        },
        selectLeaf(item) {
            if (!item.children) { // ✅ Only allow selection if it's a leaf
                this.selectedDataCategory = item;
                console.log("Selected Leaf:", item);
                if (item.aggregations) {
                    this.aggregations = item.aggregations;
                    this.showAggregation = true;
                }
                else
                    this.showAggregation = false;
            }
        }
    }
}
</script>

<style scoped>
.panel {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 5px;
  background: #f5f5f5;
  height: 1000px;
}

.treeview-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.treeview-custom .v-treeview-node__label {
  font-size: 8px;  /* Change font size */
}

.treeview-container {
  max-height: 210px; /*Set max height */
  overflow-y: auto;  /*Enable vertical scrolling */
  border: 1px solid #ddd; /* Optional: Add border */
}
</style>