<template>
  <v-form ref="form">
    <v-row>
      <v-col sm="4" md="4" lg="2">
        <v-text-field
          color="#f60076"
          placeholder="Patient code"
          outlined
          v-model="selectedPatientCode"
          dense
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col sm="4" md="4" lg="2">
        <select-with-search
          :items="latestActivityItems"
          :label="latestActivityLabel"
          @selectedItemChanged="handleSelectedActivityChanged"
        />
      </v-col>
      <v-col sm="4" md="4" lg="2"
        ><v-select
          color="#f60076"
          label="Hospital"
          :items="hospitalItems"
          v-model="selectedHospital"
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col sm="4" md="4" lg="2">
        <v-select
          color="#f60076"
          label="Activity type"
          :items="activityTypeItems"
          v-model="selectedActivityType"
          item-text="name"
          item-value="value"
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col sm="4" md="4" lg="2">
        <v-menu
          v-model="dateFromMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              color="#f60076"
              v-model="dateFrom"
              label="Latest activity from"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              clearable
              :rules="dateFromRules"
            ></v-text-field>
          </template>
          <v-date-picker
            color="#f60076"
            v-model="dateFrom"
            :max="new Date().toLocaleDateString('en-CA')"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col sm="4" md="4" lg="2">
        <v-menu
          v-model="dateToMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              color="#f60076"
              v-model="dateTo"
              label="Latest activity to"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              clearable
              :rules="dateToRules"
            ></v-text-field>
          </template>
          <v-date-picker
            color="#f60076"
            v-model="dateTo"
            :max="new Date().toLocaleDateString('en-CA')"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SelectWithSearch from "./SelectWithSearch.vue";
import Utils from "../utils/utils";
import EventBus from "../components/EventBus";
export default {
  components: {
    SelectWithSearch,
  },
  props: {
    filtersMetadata: Object,
  },
  created: function () {
    EventBus.$on("resetGeneralFilterForm", () => {
      this.resetForm();
    });
  },
  mounted: function () {
    this.latestActivityItems = this.transformForSelector(
      this.filtersMetadata.latest_activity_time
    );
    this.hospitalItems = this.filtersMetadata.clinical_center;
    this.activityTypeItems = this.transformForSelector(
      this.filtersMetadata.latest_activities
    );
  },
  data: function () {
    return {
      selectedPatientCode: null,
      latestActivityItems: [],
      latestActivityLabel: "Latest activity",
      dateFromMenu: false,
      dateToMenu: false,
      dateFrom: null,
      dateTo: null,
      hospitalItems: [],
      selectedHospital: null,
      activityTypeItems: [
        "Environmental data",
        "Wearables data",
        "Questionnaire",
      ],
      selectedActivityType: null,
      selectedLatestActivity: null,
      dateFromRules: [
        (v) =>
          !this.dateTo ||
          new Date(v) <= new Date(this.dateTo) ||
          "Onset from must be before Onset to",
      ],
      dateToRules: [
        (v) =>
          !this.dateFrom ||
          new Date(v) >= new Date(this.dateFrom) ||
          "Onset to must be after Onset from",
      ],
    };
  },
  methods: {
    transformForSelector(items) {
      return items.map((item) => {
        return {
          name: Utils.createLabel(item),
          value: item,
        };
      });
    },
    handleSelectedActivityChanged: function (activity) {
      this.selectedLatestActivity = activity;
    },
    resetForm: function () {
      this.selectedPatientCode = null;
      this.selectedHospital = null;
      this.selectedActivityType = null;
      this.selectedLatestActivity = null;
      this.dateFrom = null;
      this.dateTo = null;
    },
  },
  computed: {
    currentGeneralFilters: function () {
      return {
        patient_code: this.selectedPatientCode,
        hospital_center: this.selectedHospital,
        latest_activity: this.selectedActivityType,
        latest_activity_time: this.selectedLatestActivity,
        latest_activity_time_from: this.dateFrom,
        latest_activity_time_to: this.dateTo,
      };
    },
  },
  watch: {
    currentGeneralFilters: function (value) {
      this.$emit("filtersChanged", value);
    },
  },
};
</script>
