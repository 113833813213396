<template>
  <v-form ref="form">
    <v-row>
      <v-col>
        <v-text-field
            color="#f60076"
            placeholder="Questionnaire code"
            outlined
            v-model="questionnaireCode"
            dense
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            color="#f60076"
            placeholder="Questionnaire name"
            outlined
            v-model="questionnaireName"
            dense
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-select
            color="#f60076"
            label="Disease type"
            :items="diseaseTypes"
            v-model="diseaseType"
            outlined
            dense
            clearable
          ></v-select>
        </v-col>
    </v-row>
  </v-form>
</template>

<script>

import EventBus from "../components/EventBus";

export default {
  data() {
    return {
      questionnaireCode: null,
      questionnaireName: null,
      diseaseTypes: ["ALS", "MS"],
      diseaseType: null,
    };
  },
  created: function () {
    EventBus.$on("resetQuestionnaireFilterForm", () => {
      this.resetForm();
    });
  },
  methods: {
    resetForm: function () {
      this.questionnaireCode = null;
      this.questionnaireName = null;
      this.diseaseType = null;
    },
  },
  computed: {
    currentDeviceFilters: function () {
      return {
        questionnaire_code: this.questionnaireCode,
        questionnaire_name: this.questionnaireName,
        sclerosis_type: this.diseaseType
      };
    }
  },
  watch: {
    currentDeviceFilters: function (filters) {
      this.$emit("filtersChanged", filters);
    },
  },
};
</script>
