<template>
  <v-form ref="form" @submit.prevent="saveCohort">
    <v-row>
      <v-col cols="12">
        <v-text-field
          color="#f60076"
          placeholder="Cohort name"
          outlined
          v-model="cohortName"
          dense
          clearable
          :rules="[(v) => !!v || 'Cohort name is required']"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6"
        ><v-select
          color="#f60076"
          label="Age group"
          :items="filters.age_groups"
          v-model="selectedAgeGroups"
          multiple
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col cols="6"
        ><v-select
          color="#f60076"
          label="Gender"
          :items="filters.gender"
          v-model="selectedGenders"
          multiple
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"
        ><v-select
          color="#f60076"
          label="Marital status"
          :items="filters.marital_status"
          v-model="selectedMaritalStatuses"
          multiple
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col cols="6"
        ><v-select
          color="#f60076"
          label="Ethnicity"
          :items="filters.ethnicity"
          v-model="selectedEthnicity"
          multiple
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"
        ><v-select
          color="#f60076"
          label="Hospital"
          :items="filters.hospitals"
          v-model="selectedHospitals"
          multiple
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col cols="6"
        ><v-select
          color="#f60076"
          label="Sclerosis type"
          :items="filters.sclerosis_type"
          v-model="selectedSclerosisType"
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-menu
          v-model="dateFromMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              color="#f60076"
              v-model="onsetFrom"
              label="Onset from"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              clearable
              :rules="dateFromRules"
            ></v-text-field>
          </template>
          <v-date-picker
            color="#f60076"
            v-model="onsetFrom"
            :max="new Date().toLocaleDateString('en-CA')"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-menu
          v-model="dateToMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              color="#f60076"
              v-model="onsetTo"
              label="Onset to"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              clearable
              :rules="dateToRules"
            ></v-text-field>
          </template>
          <v-date-picker
            color="#f60076"
            v-model="onsetTo"
            :max="new Date().toLocaleDateString('en-CA')"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-btn type="submit" color="#f60076" class="white--text">
      Save Cohort
    </v-btn>
  </v-form>
</template>

<script>
import EventBus from "../components/EventBus";
export default {
  props: {
    filters: Object,
  },
  created: function () {
    EventBus.$on("clearAddCohortFilters", () => {
      console.log("should clear cohort filters!!!!");
      this.$refs.form.reset();
    });
  },
  data: function () {
    return {
      cohortName: null,
      age_groups: [],
      selectedAgeGroups: null,
      gender: [],
      selectedGenders: null,
      marital_status: [],
      selectedMaritalStatuses: null,
      ethnicity: [],
      selectedEthnicity: null,
      sclerosis_type: [],
      selectedSclerosisType: null,
      hospitals: [],
      selectedHospitals: null,
      onsetFrom: null,
      onsetTo: null,
      dateFromMenu: false,
      dateToMenu: false,
      dateFromRules: [
        (v) =>
          !this.onsetTo ||
          new Date(v) <= new Date(this.onsetTo) ||
          "Onset from must be before Onset to",
      ],
      dateToRules: [
        (v) =>
          !this.onsetFrom ||
          new Date(v) >= new Date(this.onsetFrom) ||
          "Onset to must be after Onset from",
      ],
    };
  },
  methods: {
    saveCohort: function () {
      if (this.$refs.form.validate()) {
        const filtersForRequest = {
          cohort_name: this.cohortName,
          age_groups: this.selectedAgeGroups,
          gender: this.selectedGenders,
          marital_status: this.selectedMaritalStatuses,
          ethnicity: this.selectedEthnicity,
          hospitals: this.selectedHospitals,
          sclerosis_type: this.selectedSclerosisType,
          onset_from: this.onsetFrom,
          onset_to: this.onsetTo,
        };
        this.$emit("saveCohort", filtersForRequest);
      }
    },
  },
};
</script>
