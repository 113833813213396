<template>
  <div>
    <v-dialog v-model="garminDialog" max-width="600">
      <v-card>
        <v-toolbar flat color="#f5f5f5" dense
          ><span>Garmin data backfill</span></v-toolbar
        >
        <v-card-text class="mt-10">
          <v-form ref="garminBackfill" v-model="isValid">
            <v-row>
              <v-col>
                <v-menu
                  v-model="garminBackfillDateFromMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      color="#f60076"
                      v-model="garminBackfillDateFrom"
                      label="Backfill date from"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      :rules="garminDateFromRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="garminBackfillDateFrom"
                    :max="new Date().toLocaleDateString('en-CA')"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="garminBackfillDateToMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      color="#f60076"
                      v-model="garminBackfillDateTo"
                      label="Backfill date to"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      :rules="garminDateToRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="garminBackfillDateTo"
                    :max="new Date().toLocaleDateString('en-CA')"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="selectedGarminDataSource"
                  :items="garminDataSourceItems"
                  label="Garmin data source"
                  outlined
                  dense
                  :rules="[requiredRule]"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- ✅ Snackbar Notification -->
        <v-snackbar v-model="snackbar.show" :timeout="3000" :color="snackbar.color">
          {{ snackbar.message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!isLoading" color="pink darken-1" text @click="garminDataBackfill">
            Backfill
          </v-btn>
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            size="20"
            color="blue"
          ></v-progress-circular>
          <v-btn color="pink darken-1" text @click="garminDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="atmotubeDialog" persistent max-width="600">
      <v-card>
        <v-toolbar flat color="#f5f5f5" dense
          ><span>Atmotube data backfill</span>
        </v-toolbar
        >
        <v-card-text class="mt-10">
          <v-form ref="atmotubeBackfill" v-model="isValid">
            <v-row>
              <v-col>
                <v-menu
                  v-model="atmotubeBackfillDateFromMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      color="#f60076"
                      v-model="atmotubeBackfillDateFrom"
                      label="Backfill date from"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      :rules="atmotubeDateFromRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="atmotubeBackfillDateFrom"
                    :max="new Date().toLocaleDateString('en-CA')"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="atmotubeBackfillDateToMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      color="#f60076"
                      v-model="atmotubeBackfillDateTo"
                      label="Backfill date to"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      :rules="atmotubeDateToRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="atmotubeBackfillDateTo"
                    :max="new Date().toLocaleDateString('en-CA')"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- ✅ Snackbar Notification -->
        <v-snackbar v-model="snackbar.show" :timeout="3000" :color="snackbar.color">
          {{ snackbar.message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!isLoading" color="pink darken-1" text @click="atmotubeDataBackfill">
            Backfill
          </v-btn>
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            size="20"
            color="blue"
          ></v-progress-circular>
          <v-btn color="pink darken-1" text @click="atmotubeDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tabs
      v-model="tabs"
      active-class="custom-hover"
      style="width: 200px; margin: 0 auto"
      fixed-tabs
      height="40"
      color="#f60076"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            top
            v-bind="attrs"
            v-on="on"
            href="#mobile-tabs-5-1"
            class="primary--text"
          >
            <v-icon color="#f60076">mdi-information</v-icon>
          </v-tab>
        </template>
        <span>Basic information</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-bind="attrs"
            v-on="on"
            href="#mobile-tabs-5-2"
            class="primary--text"
          >
            <v-icon color="#f60076">mdi-database</v-icon>
          </v-tab>
        </template>
        <span>Data</span>
      </v-tooltip>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item v-for="i in 2" :key="i" :value="'mobile-tabs-5-' + i">
        <v-card flat>
          <v-card-text>
            <template v-if="i === 1">
              <v-row justify="center">
                <v-col
                  cols="6"
                  v-for="item in devices.basic_info"
                  :key="item.serial_number"
                >
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <b><span>Category:</span></b
                          ><br />
                          <b><span>Tracker brand:</span></b
                          ><br />
                          <b><span>Tracker model:</span></b
                          ><br />
                          <b><span>Internal device code:</span></b
                          ><br />
                          <b><span>Serial number:</span></b
                          ><br />
                          <b><span>Installation status:</span></b
                          ><br />
                          <b><span>Tracker installation date:</span></b
                          ><br />
                          <b><span>Latest synchronization:</span></b
                          ><br />
                        </v-col>
                        <v-col>
                          <span>{{ item.category }}</span
                          ><br />
                          <span>{{ item.brand }}</span
                          ><br />
                          <span>{{ item.model }}</span
                          ><br />
                          <span>{{ item.internal_device_code }}</span
                          ><br />
                          <span>{{ item.serial_number || "N/A" }}</span
                          ><br />
                          <span>{{ item.installation_status || "N/A" }}</span
                          ><br />
                          <span>{{ item.installation_date || "N/A" }}</span
                          ><br />
                          <span>{{ item.last_sync || "N/A" }}</span
                          ><br />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-else-if="i === 2">
              <v-card>
                <template>
                  <v-simple-table
                    dense
                    height="225px"
                    class="mt-5"
                    fixed-header
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Variable name</th>
                          <th class="text-left">Number collected</th>
                          <th class="text-left">
                            Last date collected
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in devices.data.table_data"
                          :key="index"
                        >
                          <td>{{ item.var_name }}</td>
                          <td>{{ item.num_collected }}</td>
                          <td>{{ item.latest_date_acquired }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-card>
              <Chart
                :histogramData="devices.data.histogram_data.wearable_trackers"
              />
              <Chart
                :histogramData="
                  devices.data.histogram_data.environmental_trackers
                "
              />
            </template>
            <v-row class="text-center mt-5">
              <v-col>
                <v-btn
                  @click="garminDialog = true"
                  class="white--text"
                  color="#f60076"
                  width="230px"
                  small
                  >Garmin data backfill</v-btn
                >
              </v-col>
              <v-col>
                <v-btn
                  @click="atmotubeDialog = true"
                  class="white--text"
                  color="#f60076"
                  width="230px"
                  small
                  >Atmotube data backfill</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Chart from "../components/Chart.vue";
import Services from "../rest/Services";
export default {
  props: {
    devices: Object,
    patientCode: String
  },
  components: {
    Chart,
  },
  data() {
    return {
      isValid: false,
      isLoading: false,
      tabs: null,
      heart_rate_burn_time_val: 360,
      still_calories_val: 377,
      walk_steps_val: 352,
      walk_distance_val: 385,
      stairs_floor_changes_up_val: 354,
      heart_rate_burn_time_timestamp: "2023-01-01 01:00:00",
      still_calories_timestamp: "2023-01-01 01:00:00",
      walk_steps_timestamp: "2023-01-01 01:00:00",
      walk_distance_timestamp: "2023-01-01 01:00:00",
      stairs_floor_changes_up_timestamp: "2023-01-01 01:00:00",

      atmotubeBackfillDateFromMenu: null,
      garminBackfillDateFromMenu: null,
      atmotubeBackfillDateFrom: null,
      garminBackfillDateFrom: null,
      atmotubeBackfillDateToMenu: null,
      garminBackfillDateToMenu: null,
      atmotubeBackfillDateTo: null,
      garminBackfillDateTo: null,
      garminDialog: false,
      selectedGarminDataSource: null,
      garminDataSourceItems: ["dailies", "sleeps"],
      atmotubeDialog: false,
      requiredRule: (v) => !!v || "This field is required",
      garminDateFromRules: [
        (v) =>
          !this.garminBackfillDateTo ||
          new Date(v) <= new Date(this.garminBackfillDateTo) ||
          "Start must not be after End",
          (v) => !!v || "This field is required"
      ],
      atmotubeDateFromRules: [
        (v) =>
          !this.atmotubeBackfillDateTo ||
          new Date(v) <= new Date(this.atmotubeBackfillDateTo) ||
          "Start must not be after End",
          (v) => !!v || "This field is required"
      ],
      garminDateToRules: [
        (v) =>
          !this.garminBackfillDateFrom ||
          new Date(v) >= new Date(this.garminBackfillDateFrom) ||
          "End must not be before Start",
          (v) => {
            if (!this.garminBackfillDateFrom) return true;

            let maxAllowedDate = new Date(this.garminBackfillDateFrom);
            maxAllowedDate.setDate(maxAllowedDate.getDate() + 30); 

            return new Date(v) <= maxAllowedDate || "Can't backfill for more than 30 days";
          }
      ],
      atmotubeDateToRules: [
        (v) =>
          !this.atmotubeBackfillDateFrom ||
          new Date(v) >= new Date(this.atmotubeBackfillDateFrom) ||
          "End must not be before Start",
          (v) => {
            if (!this.atmotubeBackfillDateFrom) return true;

            let maxAllowedDate = new Date(this.atmotubeBackfillDateFrom);
            maxAllowedDate.setDate(maxAllowedDate.getDate() + 7); 

            return new Date(v) <= maxAllowedDate || "Can't backfill for more than 7 days";
          }
      ],

      // Snackbar Notification
      snackbar: {
        show: false,
        message: "",
        color: "success", // Default color
      },
    };
  },
  methods: {
    showNotification(message, type = "success") {
      this.snackbar.message = message;
      this.snackbar.color = type === "error" ? "red darken-2" : "green darken-2";
      this.snackbar.show = true;
    },
    garminDataBackfill: function () {
      if (!this.$refs.garminBackfill.validate()) {
        console.log("Validation failed!");
        return;
      }
      this.isLoading = true;
      //console.log(this.patientCode);
      let params = {
        source: this.selectedGarminDataSource,
        access_code: this.patientCode,
        start_time: this.garminBackfillDateFrom,
        end_time: this.garminBackfillDateTo
      }

      Services.garminBackfill(params).then((response) => {
        console.log(response);
        //console.log(response.data);
        // ✅ Show success notification
        this.showNotification("Backfill queued successfully!");

        // Reset form after successful submission
        this.$refs.garminBackfill.reset();
        this.isLoading = false;
        }
      ).catch((error) => {
        
        console.log("Error adding device:", error);
        this.showNotification("Backfill error", "error");
        this.isLoading = false;
      });

    },
    atmotubeDataBackfill: function () {
      if (!this.$refs.atmotubeBackfill.validate()) {
        console.log("Validation failed!");
        return;
      }
      this.isLoading = true;
      let serialNumber;
      //console.log(this.devices);
      for (let i = 0; i < this.devices.basic_info.length; i++)
        if (this.devices.basic_info[i].category === 'E')
          serialNumber = this.devices.basic_info[i].internal_device_code;
      //console.log(serialNumber);

      //console.log(this.atmotubeBackfillDateFrom);

      let params = {
        internal_device_code:serialNumber,
        start_date: this.atmotubeBackfillDateFrom,
        end_date: this.atmotubeBackfillDateTo
      }
      //console.log(params);
      Services.atmotubeBackfill(params).then((response) => {
        //console.log(response);
        //console.log(response.data);
        // ✅ Show success notification
        this.showNotification("Backfill done successfully! Rows added: " + response.data.environmental_data_stored + ". Rows deleted: " + response.data.environmental_data_deleted);

        // Reset form after successful submission
        this.$refs.atmotubeBackfill.reset();
        this.isLoading = false;
        }
      ).catch((error) => {
        
        console.log("Error adding device:", error);
        this.showNotification("Backfill error", "error");
        this.isLoading = false;
      });
    },
  },
};
</script>

<style>
.custom-hover {
  background-color: white;
}
</style>
