var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.patientData,"single-expand":true,"expanded":_vm.expanded,"show-expand":"","item-key":"patient_code"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var patients = ref.patients;
var item = ref.item;
return [_c('td',{attrs:{"colspan":100}},[_c('div',{staticClass:"d-flex justify-space-around"},[_c('div',[_vm._v(" Latest questionnaire submitted: "),_c('br'),_c('b',[_vm._v(_vm._s(item.latest_questionnaire_submitted))])]),_c('div',[_vm._v(" Latest wearable data collected: "),_c('br'),_c('b',[_vm._v(_vm._s(item.latest_wearable_data_collected))])]),_c('div',[_vm._v(" Latest environmental data collected "),_c('br'),_c('b',[_vm._v(_vm._s(item.latest_environmental_data_collected))])]),_c('div',[_vm._v(" Latest AI model executed: "),_c('br'),_c('b',[_vm._v(_vm._s(item.latest_ai_model_executed))])])])])]}},{key:"item.notifications",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('i',_vm._g({class:_vm.getClassObject(item.notifications.includes('questionnaire'))},on),[_vm._v("receipt_long")])]}}],null,true)},[_c('span',[_vm._v("Questionnaire notification")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({attrs:{"width":"32px","src":item.notifications.includes('position')
                  ? require('@/assets/env_sens_small.png')
                  : require('@/assets/env_sens_small_gray.png')}},on))]}}],null,true)},[_c('span',[_vm._v("Location notification")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('i',_vm._g({class:_vm.getClassObject(item.notifications.includes('wearable'))},on),[_vm._v("watch")])]}}],null,true)},[_c('span',[_vm._v("Wearable notification")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('i',_vm._g({class:_vm.getClassObject(item.notifications.includes('ai_models')),staticStyle:{"font-size":"28px"}},on),[_vm._v("psychology")])]}}],null,true)},[_c('span',[_vm._v("Ai models notification")])])],1)],1)]}},{key:"footer.prepend",fn:function(){return [_c('div',{staticStyle:{"width":"300px","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","top":"-20px"}},[_c('v-select',{staticStyle:{"width":"90%"},attrs:{"full-width":"","label":"Select file format to export","items":_vm.fileExportFormatItems,"outlined":"","dense":"","color":"#f60076"},model:{value:(_vm.selectedFileExportFormat),callback:function ($$v) {_vm.selectedFileExportFormat=$$v},expression:"selectedFileExportFormat"}}),_c('v-btn',{staticClass:"white--text mx-2",staticStyle:{"position":"absolute","right":"-80px","top":"0px","margin-top":"0"},attrs:{"color":"#f60076"},on:{"click":_vm.exportToCsv}},[_vm._v(" Export ")])],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.actionsList),function(action,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleAction(item.patient_code, action.title)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(action.title))])],1)],1)}),1)],1)]]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }